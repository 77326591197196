
.email {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
}

.ant-layout-header {
    line-height: 120%;
    color: #fff;
    font-weight: bold;
}

.logo {
    font-size: 20px;
    text-transform: uppercase;
}